.law .div-image-container {
    overflow: hidden;
}

.law .div-image-container img {
    width: 100%;
}

.law ul {
    list-style: none;
    padding-left: 1rem;
}

.law ul li a {
    font-size: 20px;
}