.concentrates .div-img-container {
    width: 100%;
    display: block;
    overflow: hidden;
    height: 200px;
    float: none;
    clear: both;
}

.concentrates .div-img-container img {
    width: 100%;

}