@media only screen and (max-width: 600px) {

    .forty-five-days label[for="startDate"] {
        display: block;
        font-weight: bold;
        color: #666;
        margin: 0.3rem;
    }

    .forty-five-days input#startDate {
        margin: 0.3rem;
        border: solid 1px #ccc;
    }

}