.Home .lander {
  padding: 0 0 80px 0;
  text-align: left;
}

.Home .lander h1 {
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.lander img.body-logo {
  width: 100%;
  display: block;
  margin: auto;
}

.lander .motto {
  font-style: italic;
}

.lander .three-col-front .img-frame, .lander .two-col-front .img-frame, .lander .two-col-front .img-frame-lg {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 250px;
}
.lander .three-col-front.bottom .img-frame {
  width: 15rem;
  height: 15rem;
  margin: 1rem auto;
  background-color: #ccc;
  border-radius: 50%;
}

.lander .two-col-front .img-frame {
  height: 200px;
}

.lander .two-col-front .img-frame-lg {
  width: 50%;
  height: 100px;
  float: left;
}

.lander .two-col-front .img-frame-lg.expand-2 {
  width: 100%;
  height: 100px;
  clear: both;
}

.lander .two-col-front .img-frame img, .lander .one-col-front img {
  width: 100%;
  margin: 0.5rem 0;
}


.lander .three-col-front .img-frame img {
  width: 100%;
}

.lander .three-col-front.bottom .img-frame img {
  height: 100%;
}

.lander .two-col-front .img-frame-lg img {
  display: block;
  height: 100%;
  margin: 0 auto;
}


.charges div.header-row {
  border-bottom: #ccc solid 2px;
  padding-bottom: 0.5rem;
}

.charges div.header-row div {
  font-weight: bold;
}

.charges div.mui-row {
  margin-bottom: 1rem;
}

.charge-list div label {
  visibility: hidden;
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}
/****

  Mobile Styles

  ****/

@media screen and (max-width: 540px) {

  .lander img.body-logo {
    width: 10rem;
  }


  .lander .two-col-front .img-frame-lg {
    width: 100%;
    height: 100px;
    float: none;
  }

  .charge-list div label {
    visibility: visible;
    width: auto;
    height: auto;
    display: block;
    overflow: hidden;
    float: left;
    font-weight: bold;
  }

  .charges .mui-row.header-row {
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }

  .charges div.mui-row {
    border-bottom: #ccc solid 2px;
    padding-bottom: 0.5rem;
  }
}