.App {
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .desktop-only {
    display: block;
    overflow: hidden;
    display: none !important;
  }
}
@media only screen and (min-width: 601px) {
  .mobile-only {
    display: block;
    overflow: hidden;
    display: none !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mui-appbar {
  background-color: #2D5400;
}

.mui-appbar div.first-header-div span {
  float: left; 
}

.mui-appbar div.first-header-div a {
  width: auto;
  overflow: hidden;
  margin: 0;
  display: block;
  float: left;
}

.mui-appbar div.first-header-div a.menu-main-link {
  margin: 0.7rem 0.5rem auto 0.5rem;
  width: 40px;
  font-size: 30px;
}

.mui-appbar div.first-header-div a.menu-title-link {
  margin: 0.3rem 0.5rem 0 0.5rem;
}

.mui-appbar div.first-header-div a.menu-title-link h1 {
  font-weight: bold;
  font-size: 1.5rem;
  float: right;
  margin-top: 0.5rem;
}

.mui-appbar div.first-header-div a.menu-title-link .header-image {
  height: 50px;
  border-radius: 50%;
  margin-top: 2px;
}

.mui-appbar .mui-col-md-6.first-header-div a:hover {
  text-decoration: none;
}

.mui-appbar .header-menu {
  margin: 0.4rem auto;
  float: right;
  clear: both;
}
.mui-appbar .header-menu li {
  list-style: none;
  float: left;
}

.mui-appbar a {
  color: #fff;
  margin: 1rem 0.5rem;
  display: block;
}


@keyframes fade-it-in-please {
  0%   {opacity: 0.2;}
  25%  {opacity: 0.4;}
  50%  {opacity: 0.6;}
  75%  {opacity: 0.8;}
  100% {opacity: 1;}
}

@keyframes fade-it-in-please-width {
  0%   {opacity: 0.0; height: 0; overflow: visible;}
  10%  {opacity: 0.1; height: 10%;overflow: visible;}
  20%  {opacity: 0.20; height: 20%;overflow: visible;}
  30%  {opacity: 0.30; height: 30%;overflow: visible;}
  40%  {opacity: 0.40; height: 40%;overflow: visible;}
  50% {opacity: 0.50; height: 50%;overflow: visible;}
  60%   {opacity: 0.60; height: 60%; overflow: visible;}
  70%  {opacity: 0.70; height: 70%;overflow: visible;}
  80%  {opacity: 0.80; height: 80%;overflow: visible;}
  90%  {opacity: 0.90; height: 90%;overflow: visible;}
  100% {opacity: 1; height: 100%;overflow: hidden;}
}

.side-menu {
  display: block;
  float: left;
  overflow: hidden;
  width: 20rem;
  background-color: rgba(209, 230, 190, 1);
  animation-name: fade-it-in-please-width;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

.side-menu-view {
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .mui-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mui-appbar .header-menu {
    padding-inline-start: 0;
  }

  .side-menu {
    float: none;
    width: 100%;
  }

  .side-menu-view {
    visibility: hidden;
    display: none;
    height: 0; 
  }
}