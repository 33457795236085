.topicals .div-image-frame {
    width: 100%;
    display: block;
    float: none;
    overflow: hidden;
    height: 200px;
}

.topicals .div-image-frame img {
    width: 100%;
}