.purchase-template form {
    margin-left: 1rem;
    margin-right: 1rem;
    padding-bottom: 15px;
}

.purchase-template form textarea {
    height: 300px;
    font-size: 18px;
}

.purchase-template legend, .purchase-template .mui-textfield, .purchase-template .mui-select {
    margin-top: 1rem;
    font-size: 15px;
}

.purchase-template .mui-radio {
    display: inline;
    margin-right: 0.5rem;
}

.purchase-template .mui-radio div.rating {
    color: #ffcc00;
    display: inline;
}

.purchase-template .mui-radio {
    clear: both;
}
