.over-21 {
    max-width: 500px;
    text-align: center;
}

.over-21 .div-image-frame {
    width: 24rem;
    overflow: hidden;
    display: block;
    margin: 0 auto;
}

.over-21 .div-image-frame img {
    width: 100%;
    display: block;
    float: none;

}

.over-21 p {
    font-size: 24px;
    font-weight: bold;
}