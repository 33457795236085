.edibles .section-area {
    margin-bottom: 1rem;
}

.edibles .div-img-frame {
    width: 100%;
    display: block;
    overflow: hidden;
    border-radius: 2%;
    height: 300px;
}

.edibles .div-img-frame img {
    width: 100%;
}

.edibles .listing h3 {
    font-size: 16px;
}