.side-menu-container {
    margin-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
}

.side-menu-header {
    background-color: #000;
    height: 4rem;
    color: #fff;
    clear: both;
    
}

.side-menu-header h2 {
    margin: 1rem 0.5rem;
    float: left;
}

.menu-close {
    display: block;
    color: #fff;
    background-color: #000;
    border: none;
    float: right;
    margin: 0.85rem 0.5rem;
    width: 40px;
    font-size: 30px;
}

.menu-close:hover {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.side-menu-container .side-menu-ul {
    list-style: none;
    padding: 0;
}

.side-menu-container .side-menu-ul li label {
    font-weight: bold;
    display: block;
    line-height: 2.5rem;
    padding: 0.4rem;
    background-color: #ccc;
}

.side-menu-container .side-menu-ul li .side-menu-sub-ul {
    list-style: none;
    padding:0;
}

.side-menu-container .side-menu-ul li .side-menu-sub-ul li {
    border-bottom: solid #ccc 1px;
}

.side-menu-container .side-menu-ul li .side-menu-sub-ul li:last-child {
    border-bottom: 0;
}

.side-menu-container .side-menu-ul li .side-menu-sub-ul li a {
    line-height: 2.5rem;
    display: block;
    color: #000;
    padding: 0.4rem 0 0.4rem 1rem;
}

.side-menu-container .side-menu-ul li .side-menu-sub-ul li a:hover {
    background-color: #666;
}

@media screen and (max-width: 540px) {
    .side-menu-header {
        margin-top: 0;
        margin-bottom: 0;
    }
}